<template>
  <div class="section-content pt-4" :class="{ 'mt-10': $vuetify.breakpoint.xs }">
    <div ref="rmTopSection">
      <hb-header full :divider="false">
        <hb-page-header title="Close of Day"
          description="Manage the details of Close of Day. Description for close of day ">
        </hb-page-header>
      </hb-header>
    </div>
    <template>
      <div class="section-content pt-0 pr-0">
        <v-divider></v-divider>
        <HbPropertySelector id="property" :items="facilityList" item-text="name" item-value="id" v-validate="'required'"
          v-model.lazy="property_id" data-vv-scope="coseOfDay" data-vv-name="property" data-vv-as="Property"
          :error="errors.has('property')" />
        <div class="mr-6">
          <hb-empty-state v-if="!property_id"
            message="Please select a Property to continue." :showBtn="false">
          </hb-empty-state>
          <div v-else>
            <close-of-day-list :property_id="property_id" />
          </div>
        </div>

      </div>
    </template>
  </div>
</template>


<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import CloseOfDayList from "./CloseOfDayList.vue";
import { notificationMixin } from '@/mixins/notificationMixin.js';

export default {
  name: "CloseOfDay",
  mixins: [notificationMixin],
  data() {
    return {
      property_id: '',
      level: "Property",
      activeTab: 0
    };
  },
  props: {
    facilityID: {
      type: String,
      default: ""
    }
  },
  components: {
    CloseOfDayList
  },
  async created() {
    this.property_id = this.defaultProperty ? this.defaultProperty.id : null;
  },
  mounted() {
    if(!this.hasPermission('close_of_day_config') || !this.isCorporateCODEnabled) this.$router.push('/dashboard');
  },
  destroyed() {
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      isCorporateCODEnabled: 'closeOfDayStore/isCorporateCODEnabled',
      defaultProperty: "propertiesStore/defaultProperty"
    })
  },
  methods: {
    ...mapActions({
      setDefaultProperty: 'propertiesStore/setDefaultProperty',
    }),
  },
};
</script>

<style scoped>
.tab-bar {
  color: #101318;
  text-transform: none;
  background: #f9fafb;
}

.tab-item {
  background: #f9fafb;
  height: 100%;
  width: 100%;
}

.close-of-day-tab-items {
  background: #f9fafb;
  overflow: visible;
}
</style>
